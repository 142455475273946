<template>
  <div class="ml-24 mr-24 mb-24 mt-16">
    <a-select class="localization-picker" data-cy="localization-picker" :value="defaultLanguage" @change="onChange">
      <a-icon slot="suffixIcon" type="caret-down"/>
      <a-select-option data-cy="localization-picker-option-english" value="en">English</a-select-option>
      <a-select-option data-cy="localization-picker-option-french" value="fr">French</a-select-option>
    </a-select>
  </div>
</template>

<script>
import { loadLanguageAsync } from '../localization';
import { getLocale, setLocale } from '../util/util';

export default {
  name: 'LocalizationPicker',
  data() {
    return {
      defaultLanguage: getLocale()
    };
  },
  methods: {
    onChange(value) {
      loadLanguageAsync(value);
      this.defaultLanguage = value;
      setLocale(value);
    }
  }
};
</script>

<style lang="scss">
  @import '../assets/scss/variables';

  .localization-picker {
    width: 100%;

    .ant-select-selection {
      color: $color-link;
      height: 40px;
    }
  }
</style>
